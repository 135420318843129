import ChartGroup from "../common/ChartGroup";

function MalamatinaChart() {
    const items = [
        {
            text: "X-Axis",
            label: "V-RMS_X",
            device_id: "5acaa7ae-3170-4275-9ee0-59b0301991eb_V-RMS_X",
            color: 'rgb(53, 162, 235)'
        },
        {
            text: "Y-Axis",
            label: "V-RMS_Y",
            device_id: "5acaa7ae-3170-4275-9ee0-59b0301991eb_V-RMS_Y",
            color: 'rgb(0, 190, 240)'
        },
        {
            text: "Z-Axis",
            label: "V-RMS_Z",
            device_id: "5acaa7ae-3170-4275-9ee0-59b0301991eb_V-RMS_Z",
            color: 'rgb(0, 214, 220)'
        },
        {
            text: "Temperature",
            label: "Temperature",
            device_id: "5acaa7ae-3170-4275-9ee0-59b0301991eb_Temperature",
            color: 'rgb(42, 232, 181)'
        },
    ]
    return (
        <ChartGroup items={items} />
    );
}

export default MalamatinaChart;