import ChartGroup from "../common/ChartGroup";

function AlumilPowerChart(props) {
    const items = [
        {
            text: "Current1",
            label: "Current1",
            device_id: "75633d5f-55e0-4dc2-a6d9-f5cc2cb2a83f_Current1",
            color: 'rgb(53, 162, 235)'
        },
        {
            text: "Current2",
            label: "Current2",
            device_id: "75633d5f-55e0-4dc2-a6d9-f5cc2cb2a83f_Current2",
            color: 'rgb(0, 190, 240)'
        },
        {
            text: "Current3",
            label: "Current3",
            device_id: "75633d5f-55e0-4dc2-a6d9-f5cc2cb2a83f_Current3",
            color: 'rgb(0, 214, 220)'
        },
        {
            text: "Voltage1",
            label: "Voltage1",
            device_id: "75633d5f-55e0-4dc2-a6d9-f5cc2cb2a83f_Voltage1",
            color: 'rgb(42, 232, 181)'
        },
        {
            text: "Voltage2",
            label: "Voltage2",
            device_id: "75633d5f-55e0-4dc2-a6d9-f5cc2cb2a83f_Voltage2",
            color: 'rgb(47, 221, 146)'
        },
        {
            text: "Voltage3",
            label: "Voltage3",
            device_id: "75633d5f-55e0-4dc2-a6d9-f5cc2cb2a83f_Voltage3",
            color: 'rgb(57, 226, 131)'
        },
    ]
    return (
        <ChartGroup items={items} />
    );
}

export default AlumilPowerChart;
