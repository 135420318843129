import {Line} from 'react-chartjs-2';
import {useEffect, useState} from "react";
import {
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip,
} from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

function Chart(props) {

    const [labels, setLabels] = useState([]);
    const [remoteData, setRemoteData] = useState(undefined);
    const [lastTimestamp, setLastTimestamp] = useState(undefined);
    const [selectedPeriod, setSelectedPeriod] = useState(undefined);
    const [fetchNewData, setFetchNewData] = useState(false);

    useEffect(() => {
        const interval = setInterval(() => {
            setFetchNewData(true);
        }, 60000);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        if (fetchNewData) {
            fetchData(lastTimestamp);
            setFetchNewData(false);
        }
    }, [fetchNewData]);

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (props.selectedValue) {
            if (selectedPeriod) {
                if (selectedPeriod !== props.selectedValue) {
                    fetchData();
                    setSelectedPeriod(props.selectedValue);
                }
            } else {
                setSelectedPeriod(props.selectedValue);
            }
        }
    }, [props.selectedValue, selectedPeriod]);

    useEffect(() => {
        if (remoteData) {
            const valuesArray = [];
            Object.keys(remoteData).forEach((item) => {
                valuesArray.push(item);
            });
            setLabels(valuesArray);
        }
    }, [remoteData]);

    const fetchData = (definedLastTimestamp = undefined) => {
        let nowTime = new Date();
        let timestamp = props.dateFrom ? Date.parse(props.dateFrom) : nowTime.setHours(nowTime.getHours() - props.selectedValue).toString();
        if (definedLastTimestamp) {
            timestamp = definedLastTimestamp;
        }
        fetch('https://px7fz0e189.execute-api.eu-central-1.amazonaws.com/data?device_id=' + props.device_id + '&timestamp=' + timestamp + (props.dateTo ? "&new_timestamp=" + Date.parse(props.dateTo) : ""))
            .then(response => response.json())
            .then(response_data => {
                const values = {};
                let newLastTimestamp = '';
                response_data.Items.forEach((key) => {
                    values[new Date(parseInt(key.timestamp.N)).toISOString().split('.')[0].split('T')[0] + " " + new Date(parseInt(key.timestamp.N)).toLocaleTimeString()] = parseFloat(key.value.N);
                    newLastTimestamp = key.timestamp.N;
                });
                if (definedLastTimestamp) {
                    pushValues(values);
                } else {
                    setRemoteData(values);
                }
                setLastTimestamp(newLastTimestamp);
            });
    }

    const pushValues = (values) => {
        const newRemoteData = {...remoteData};
        // let r = (Math.random() + 1).toString(36).substring(7);
        Object.entries(values).forEach((item) => {
            newRemoteData[item[0]] = item[1];
        });
        setRemoteData(newRemoteData);
    };

    return (
        <>
            <Line options={{
                responsive: true,
                plugins: {
                    legend: {
                        position: 'top',
                    },
                    title: {
                        display: true,
                        text: props.text,
                    },
                },
            }} data={{
                labels,
                datasets: [
                    {
                        label: props.label,
                        data: labels.map((key) => remoteData[key]),
                        borderColor: props.color,
                        backgroundColor: props.color,
                    },
                ],
            }}/>
        </>
    )

}

export default Chart;