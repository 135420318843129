import {Button, Grid, Tab, Tabs, TextField} from "@mui/material";
import Chart from "./Chart";
import {useEffect, useState} from "react";
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";

function ChartGroup(props) {
    const [selectedValue, setSelectedValue] = useState("168");
    const [dateFrom, setDateFrom] = useState(null);
    const [dateTo, setDateTo] = useState(null);
    const [buttons] = useState([
        {
            label: "1h",
            value: "1",
        },
        {
            label: "8h",
            value: "8",
        },
        {
            label: "1d",
            value: "24",
        },
        {
            label: "1w",
            value: "168",
        },
        {
            label: "1M",
            value: "730",
        },
        {
            label: "3M",
            value: "2190",
        },
    ]);

    const handleValueChange = (event, newValue) => {
        setSelectedValue(newValue);
    }

    const handleDateFromChange = (newValue) => {
        setDateFrom(newValue);
    }

    const handleDateToChange = (newValue) => {
        setDateTo(newValue);
    }


    return (
        <Grid container>
            <Grid
                item
                container
                xs={12}
                style={{marginTop: '8px'}}
            >
                <Tabs
                    value={selectedValue}
                    onChange={handleValueChange}
                >
                    {buttons.map((item) => (
                        <Tab
                            key={item.value}
                            value={item.value}
                            label={item.label}
                        />
                    ))}
                </Tabs>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                        label="Date from"
                        inputFormat="MM/dd/yyyy"
                        value={dateFrom}
                        onChange={handleDateFromChange}
                        renderInput={(params) => <TextField {...params} />}
                    />
                    <DesktopDatePicker
                        label="Date to"
                        inputFormat="MM/dd/yyyy"
                        value={dateTo}
                        onChange={handleDateToChange}
                        renderInput={(params) => <TextField {...params} />}
                    />
                </LocalizationProvider>
            </Grid>
            {props.items.map((item) => (
                <Grid
                    key={item.device_id}
                    item
                    xs={12}
                    md={12}
                >
                    <Chart
                        text={item.text}
                        label={item.label}
                        device_id={item.device_id}
                        color={item.color}
                        selectedValue={selectedValue}
                        dateFrom={dateFrom}
                        dateTo={dateTo}
                    />
                </Grid>
            ))}
        </Grid>
    )
}

export default ChartGroup;
