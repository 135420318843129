import ChartGroup from "../common/ChartGroup";

function PyramisChart(props) {
    const items = [
        {
            text: "a-Peek",
            label: "a-Peek",
            device_id: "10afcd43-f96c-47ad-b577-32a8e551c825_a-Peak_" + props.num,
            color: 'rgb(53, 162, 235)'
        },
        {
            text: "a-RMS",
            label: "a-RMS",
            device_id: "10afcd43-f96c-47ad-b577-32a8e551c825_a-RMS_" + props.num,
            color: 'rgb(0, 190, 240)'
        },
        {
            text: "Crest",
            label: "Crest",
            device_id: "10afcd43-f96c-47ad-b577-32a8e551c825_Crest_" + props.num,
            color: 'rgb(0, 214, 220)'
        },
        {
            text: "Temperature",
            label: "Temperature",
            device_id: "10afcd43-f96c-47ad-b577-32a8e551c825_Temperature_" + props.num,
            color: 'rgb(42, 232, 181)'
        },
        {
            text: "V-RMS",
            label: "V-RMS",
            device_id: "10afcd43-f96c-47ad-b577-32a8e551c825_V-RMS_" + props.num,
            color: 'rgb(47, 221, 146)'
        },
    ]
    return (
        <ChartGroup items={items} />
    );
}

export default PyramisChart;