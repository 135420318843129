import './App.css';
import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom";
import MalamatinaChart from "./clients/MalamatinaChart";
import AlumilChart from "./clients/AlumilChart";
import PyramisChart from "./clients/PyramisChart";
import AlumilPowerChart from "./clients/AlumilPowerChart";

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/malamatina" element={<MalamatinaChart />} />
                <Route path="/alumil1" element={<AlumilChart num={1} />} />
                <Route path="/alumil2" element={<AlumilChart num={2} />} />
                <Route path="/alumil3" element={<AlumilChart num={3} />} />
                <Route path="/alumil4" element={<AlumilPowerChart />} />
                <Route path="/pyramis" element={<PyramisChart num={1} />} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
